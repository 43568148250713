$font-primary: "HelveticaNeue", sans-serif;
$font-secondary: "BodoniMT", sans-serif;
$font-tertiary: "HelveticaNeue-Medium", sans-serif;

$font-list: (
    ('HelveticaNeue', 'HelveticaNeue-Light', 100, normal),
    ('HelveticaNeue', 'HelveticaNeue', normal, normal),
    ('HelveticaNeue-Medium', 'HelveticaNeue-Medium', 500, normal),
    ('BodoniMT', 'BodoniMT', 700, normal),
);

@import "@lora/font-settings";