// Article
$content-list-article-border-bottom: none;
$content-list-article-border-bottom--large: $global-border;

// Title
$content-list-title-font: #{rem-calc(32)} / 1 $font-secondary;
$content-list-title-font--large: #{rem-calc(38)} / rem-calc(40) $font-secondary;
$content-list-title-text-align--large: left;
$content-list-title-text-transform: none;
$content-list-title-text-transform--large: none;
$content-list-title-margin: rem-calc(10 0 20);
$content-list-title-margin--large: rem-calc(0 0 17);

// Description
$content-list-description-text-align--large: left;
$content-list-description-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$content-list-description-font--large: #{rem-calc(14)} / rem-calc(20) $font-primary;
$content-list-description-text-transform: none;
$content-list-description-text-transform--large: none;
$content-list-description-margin--large: 0;

// First Article
$content-list-article-first-margin--large: rem-calc(0 0 50);
$content-list-article-border-top: 0;

// Last Article
$content-list-article-margin: rem-calc(0 0 20);
$content-list-article-margin--large: rem-calc(0 0 50);
$content-list-article-padding: rem-calc(0 0 5);
$content-list-article-padding--large: rem-calc(0 0 50);
$content-list-article-last-margin: $content-list-article-margin;
$content-list-article-last-margin--large: $content-list-article-margin--large;
$content-list-article-last-padding: $content-list-article-padding;
$content-list-article-last-padding--large: $content-list-article-padding--large;
$content-list-article-last-border-bottom: none;
$content-list-article-last-border-bottom--large: $content-list-article-last-border-bottom;

@import "@lora/05-components/content-list";