// Arrows
$scrollable-arrow-sizes: (
    default: (
        arrow-width: rem-calc(48),
        arrow-height: rem-calc(48),
        arrow-icon-width: rem-calc(15),
        arrow-icon-height: rem-calc(24),
        arrow-icon: chevron-right
    ),
    small: (
        arrow-width: rem-calc(40),
        arrow-height: rem-calc(30),
        arrow-icon-width: rem-calc(6),
        arrow-icon-height: rem-calc(11),
        arrow-icon: chevron-right
    ),
    medium: (
        arrow-width: rem-calc(32),
        arrow-height: rem-calc(32),
        arrow-icon-width: rem-calc(10),
        arrow-icon-height: rem-calc(10),
        arrow-icon: default-right
    )
);

$scrollable-arrow-border-radius: 50%;

@import "@lora/05-components/scrollable";