$stacked-links-link-active-color: color(secondary-active);
$stacked-links-link-hover-color: color(secondary-active);
$stacked-links-link-font: 100 #{rem-calc(12)} / 1.25 $font-primary;
$stacked-links-link-hover-font: 500 #{rem-calc(12)} / 1.25 $font-primary;
$stacked-links-link-active-font: 500 #{rem-calc(12)} / 1.25 $font-primary;
$stacked-links-link-logout-background: color(primary);
$stacked-links-link-logout-text-transform: uppercase;
$stacked-links-link-logout-margin: rem-calc(20 10);
$stacked-links-link-logout-border-radius: rem-calc(40);
$stacked-links-border: 0;
$stacked-links-border-top: rem-calc(1) solid color(border);
$stacked-links-item-first-child-border-top: 0;
$stacked-links-link-logout-hover-background: color(primary-active);
$stacked-links-link-logout-hover-color: color(secondary-active);

@import "@lora/05-components/stacked-links";