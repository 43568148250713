$content-description-font-size: rem-calc(14);
$content-description-font-size--large: rem-calc(14);

// Content Title
$content-title-margin--large: rem-calc(0 0 30 0);
$content-title-font: #{rem-calc(32)} / rem-calc(40) $font-secondary;
$content-title-font--large: #{rem-calc(38)} / rem-calc(40) $font-secondary;
$content-title-text-transform: none;
$content-title-width: 100%;
$content-title-width--large: 100%;
$content-title-max-width--large: rem-calc(400);

// Content Section
$content-section-padding--large: rem-calc(50 100 60);
$content-section-item-margin: rem-calc(0 0 20);
$content-section-item-margin--large: rem-calc(0 0 20);

// Sidebar and Content
$content-sidebar-columns--large: 3 of 12;
$content-container-columns--large: 9 of 12;

// Breadcrumbs
$content-main-top-border-bottom: none;

//Content container
$content-container-margin--large: 0 auto;

// Content Centered
$content-centered-secondary-background: none;
$content-centered-secondary-background--large: none;

@import "@lora/04-layout/content";