@import "@lora/03-base/colors";
$palette-custom: (
    light:     #fff, // White
    dark:      #000,// Black
);

$semantic-colors-custom: (
    primary:             #000,
    primary-active:      #edd6ad,
    secondary:           #fff,
    secondary-active:    #000,
    text:                #000,
    text-secondary:      #525252,
    success:             #276027,
    alert:               #ab000d,
    disabled:            #ccc,
    element-background:  #f8f4eb,
    global-background:   #f2f2f2,
    organism-background: #525252,
    border:              #d8d8d8,
    results-background:  #f8f4eb,
    secondary-background:#e5e5e5,
    element:             #5f5f5f,
    organism:            #dbd7d3,
    secondary-border:    #9b9b9b,
    text-background:     #f3f3f3,
    success-active:      #edf9f2,
    category-background: #fdfcf9
);

$semantic-colors: map-extend($semantic-colors-default, $semantic-colors-custom);
$palette: map-extend($palette-default, $palette-custom);